<template>
	<div class="wrapper">
		<template>	
			<div class="fullscreen dark">
			<div class="container">
				<div class="grid" :class="{ 'grid-without-syntax': !isSyntax, 'grid-info-full': isSyntax }">
					<div class="grid-item syntax" v-if="isSyntax">
						<p class="big-text">Пример синтаксиса</p>
						<p v-for="(item, key) in syntax" :key="key">
							{{ item }}
						</p>
					</div>
					<div class="grid-item money" v-if="isMoney">
						<p class="big-text">Средняя ЗП в РК</p>
						<p>{{ thisCourse.money }}₸</p>
					</div>
					<div class="popularity" v-if="isPopularity">
						<p class="big-text">Популярность</p>
						<p>{{ thisCourse.popularity }}</p>
					</div>
					<div class="sertificate">
						<p class="big-text">Сертификат</p>
						<p>Да</p>
					</div>
					<div class="grid-item usage" v-if="isUsage">
						<p class="big-text">Области применения</p>
						<ul>
							<li v-for="(item, key) in usage" :key="key">
								{{ item }}
							</li>
						</ul>
					</div>
					<div class="grid-item interval" v-if="isInterval">
						<p class="big-text">Срок обучения</p>
						<p>{{ thisCourse.date_interval }} месяца</p>
					</div>
					<div class="grid-item projects">
						<p class="big-text">Количество проектов</p>
						<p>{{ thisCourse.num_projects }}</p>
					</div>
				</div>
			</div>
		</div>
	</template>
	
		<template>
			<div class="fullscreen light">
			<div class="small dark-text">Программа курса</div>
			<div class="container">
				<div class="left" v-if="program.length > 0">
					<!-- <div class="fullscreen gap" v-for="(chunk, index) in chunkedProgram" :key="index"> -->
						<p v-for="(item, key) in program" :key="key">
							<span class="module">Модуль {{ key + 1 }}</span>{{ item.theme }}
						</p>
					<!-- </div> -->
					<div class="btn-dark"><router-link class="dark-text-button" to="/courses">К курсам</router-link></div>
				</div>
				<div v-else class="loading dark-text center">
					<p class="bigger">{{ this.text }}</p>
					<div class="lds-ring darken">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	</template>

	

		<template>	
			<footer class="fullscreen">
			<div class="container flexible">
				<div class="footer__item social__block">
					<h4>Контакты</h4>
					<ul>
						<li><a class="footer-link" href="https://wa.me/77770635167" target="_blank">8 (777) 063-51-67</a></li>
						<li><a class="footer-link" href="mailto:support@cybercourse.kz"
								target="_blank">support@cybercourse.kz</a></li>
						<li><a class="footer-link" href="https://www.instagram.com/cybercourse.kz/" target="_blank">inst:
								cybercourse.kz</a></li>
					</ul>
				</div>
				<div class="footer__item navigate__bar">
					<h4>Навигация</h4>
					<ul>
						<li><router-link to="/courses">Курсы</router-link></li>
						<!-- <li><router-link to="/news">Новости</router-link></li> -->
					</ul>
				</div>
				<div class="footer__item copyright">
					Все права защищены, 2023, Кибер Курс ©
				</div>
			</div>
		</footer>
	</template>
	
	</div>
</template>
<script>
import { mapState } from 'vuex';
import httpClient from '../../../services/http.service';
import animateService from '../../../services/animate.service';
export default {
	name: 'info_page',
	data() {
		return {
			program: [],
			last: '',
			text: '',
			isTrue: true
		};
	},
	computed: {
		...mapState(['courses']),
		chunkedProgram() {
			const chunkSize = 5;
			const programCopy = [...this.program];
			const result = [];

			while (programCopy.length > 0) {
				result.push(programCopy.splice(0, chunkSize));
			}

			return result;
		},
		thisCourse() {
			let id = parseInt(this.$route.params.id, 10);
			return this.courses.find((item) => item.id === id);
		},
		syntax() {
			return this.thisCourse.syntax.split('/')
		},
		usage() {
			return this.thisCourse.usage.split('/')
		},
		isSyntax() {
			return this.thisCourse?.syntax;
		},
		isMoney() {
			return this.thisCourse?.money;
		},
		isPopularity() {
			return this.thisCourse?.popularity;
		},
		isUsage() {
			return this.thisCourse?.usage;
		},
		isInterval() {
			return this.thisCourse?.date_interval;
		},
		coursetitle() {
			return this.$route.params.course.replace(/-/g, ' ');
		}

	},
	methods: {
		async getCourseData() {
			let id = this.$route.params.id;
			try {
				const response = await httpClient.post('courses/get-course-program-by-id?id=' + id);
				this.program = response.data;
				this.last = this.program.length + 1;
			} catch (e) {
				console.log(e);
			}
		},
		scrollToSection(index, sections) {
			animateService.scrollToSection(index, sections)
		},
		addDot(num) {
			if (this.program.length > 0) {
				return;
			}
			this.text = "Идет загрузка" + ".".repeat(num);
			if (typeof num === 'undefined') {
				num = 0;
			}
			let dots = (num + 1) % 4;
			setTimeout(() => {
				this.addDot(dots);
			}, 500);
		},
		toggleMenu() {
			let buttons = document.getElementsByClassName('burger_menu')
			for (let i = 0; i < buttons.length; i++) {
				let button = buttons[i];
				button.classList.toggle('opened_menu')
			}
			document.body.classList.toggle('close')
			let menuItems = document.getElementsByTagName('nav');
			for (let i = 0; i < menuItems.length; i++) {
				let menu = menuItems[i];
				menu.classList.toggle('open')
			}
		},
		closeMenu() {
			let buttons = document.getElementsByClassName('burger_menu')
			for (let i = 0; i < buttons.length; i++) {
				let button = buttons[i];
				button.classList.remove('opened_menu')
			}
			document.body.classList.remove('close')
			let menuItems = document.getElementsByTagName('nav');
			for (let i = 0; i < menuItems.length; i++) {
				let menu = menuItems[i];
				menu.classList.remove('open')
			}
		},
	},
	async mounted() {
		setTimeout(() => {
			this.addDot();
		}, 50);
		window.addEventListener('scroll', this.onScroll);
		await this.getCourseData();
		if (animateService.isTouchscreen()) {
			window.addEventListener('touchmove', this.onScroll);
		}
}
}
</script> 
<style scoped>
.left {
	margin: -25px 0px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}


.left p {
	background: var(--primary-dark-color);
	color: var(--primary-light-color);
	padding: 15px 5px;
	width: 100%;
	letter-spacing: 1.2px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1.2rem;
	position: relative;
}


.no_bg {
	background: transparent;
}

.width-1200px {
	width: 1200px;
}

.home {
	background: var(--dark-accent-color);
	color: var(--primary-light-color);
	font-size: 1.2rem;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.module {
	font-size: 1.2rem;
	font-style: italic;
	font-weight: 700;
}


.more_from_course {
	font-size: 1.7rem;
}


@import url('../../style/css/helpers/loader.css');



/* .soon::after {
	content: 'Скоро!';
	position: absolute;
	top: 25%;
	right: 20px;
} */

div.btn-dark {
	display: flex;
	width: 100%;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: transparent;
	border-color: var(--primary-dark-color);
	color: var(--primary-dark-color);
	transition: all .5s ease;
} 
div.btn-dark:hover {
	background: var(--primary-dark-color);
	
}
div.btn-dark .dark-text-button {
	color: var(--primary-dark-color);
}
.gap {
	gap: 25px;
}
.dark-text-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: all .5s ease;
	justify-content: center;
	align-items: center;
	color: var(--primary-light-color);
}
div.btn-dark:hover .dark-text-button {
	color: var(--primary-light-color);
}

.grid * {
	font-size: 1.05rem;
	font-weight: 300;
}

.big-text {
	font-size: 1.5rem;
	font-weight: 600;
	color: var(--light-accent-color);
}

.grid {
	width: 100%;
	display: grid;
	gap: 25px;
}

.grid-info-full {
	grid-template-areas:
		'syntax popularity'
		'usage money'
		'usage sertificate'
		'interval ...'
		'projects ...'
	;
}

.grid-without-syntax {
	grid-template-areas:
		'usage popularity'
		'usage money'
		'usage sertificate'
		'interval ...'
		'projects ...'
	;
}


.syntax {
	grid-area: syntax;
}

.grid-info p:not(:first-child) {
	margin: 0px 0px 8px 25px;
}

.money {
	grid-area: money;
}

.popularity {
	grid-area: popularity;
}

.usage {
	grid-area: usage;
}

.sertificate {
	grid-area: sertificate;
}

.usage li {
	margin: 0px 0px 8px 25px;
}

.usage li::before {
	content: '—';
	margin: 0px 15px 0px 0px;
}

.projects {
	grid-area: projects;
}

.interval {
	grid-area: interval;
}


@media screen and (max-width: 824px) {

	.grid-info-full {
		grid-template-areas:
			'syntax'
			'usage'
			'popularity'
			'money'
			'interval'
			'projects'
			'sertificate'
		;
	}
	.btn-primary-dark {
		min-width: 100%;
	}
	.grid-without-syntax {
		grid-template-areas:
			'usage'
			'popularity'
			'money'
			'interval'
			'projects'
			'sertificate'
		;
	}

}

.big-info {
	font-size: 2rem;
	margin: 0px 0px -45px 0px;
}

.light-accent {
	color: var(--light-accent-color);
	font-size: 2.5rem;
	margin: 45px 0px 0px 0px;
}

.bigger {
	font-size: 1.2rem;
}

.soon-feature {
	position: relative;
	display: none;
}
</style>