<template>
	<div class="wrapper">
		<div class="fullscreen relative town center">
			<header class="absolute">
				<div class="container">
					<div class="flex_menu">
						<div class="logo" @click="this.$router.push('/')"><img src="../../style/icons/logo.svg" alt="Кибер Курс">
						</div>
						<div class="burger_menu" @click="toggleMenu">
							<div class="burger"></div>
						</div>
						<nav>
							<div class="menu__list">
								<router-link class="menu__item" active-class="active_tab" @click="closeMenu" to="/">О
									компании</router-link>
								<router-link class="menu__item" active-class="active_tab" @click="closeMenu"
									to="/courses">Курсы</router-link>
								<div class="menu__item soon">Новости</div>
								<!-- <router-link class="menu__item" active-class="active_tab" @click="closeMenu"
								to="/news">Новости</router-link> -->
							</div>
						</nav>
					</div>
				</div>
			</header>
			<div class="square loaded" v-if="courses.length > 0">
				<div class="title">Наши курсы</div>
				<div class="subtitle">Исследование. Рост. Превосходство.</div>
				<button @click="scrollToCourses" class="btn">Перейти к курсам</button>
			</div>
			<div v-else class="loading">
				<p>{{ this.text }}</p>
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
		<div class="fullscreen dark">
			<div class="info__text">
				<blockquote cite="https://www.inform.kz/ru/zarplata-it-specialista-v-kazahstane_a3989713"
					class="_anim-items _no-repeat fade">
					<p class="_anim-items _no-repeat fade">По данным аналитиков HeadHunter Казахстан <a href="hh.kz">hh.kz</a>
						(крупнейшего HR агентства в стране),
						общее количество вакансий в сфере информационных технологий за 2021 год <span class="highlight">выросло
							на 52% </span>по сравнению с 2020 годом. В текущем году количество вакансий <span
							class="highlight">увеличилось еще на 35%.</span></p>
					<p class="_anim-items _no-repeat fade">Аналитики агентства отмечают, что такой резкий спрос связан с <span
							class="highlight">пандемией</span>,
						когда большинство офисов ушло на удаленный режим работы. В результате IT-специалисты стали <span
							class="highlight">очень востребованными.</span></p>
					<p class="_anim-items _no-repeat fade">После пандемии эта тенденция продолжилась. Многие работодатели
						увидели плюсы работы сотрудников на «удаленке». Кроме того, департаменты IT стали появляться в сферах, в
						которых ранее их не было - в сфере <span class="italic">торговли, общепита, различных бытовых и
							социальных услуг.</span>
					</p>
					<p class="_anim-items _no-repeat fade">В целом, по мнению экспертов, тенденция роста востребованности была и
						до пандемии в связи с глобальным
						переходом на цифровые технологии, коронавирус ее просто несколько ускорил.</p>

					<span class="highlight _anim-items _no-repeat fade">(source:
						https://www.inform.kz/ru/zarplata-it-specialista-v-kazahstane_a3989713,
						2022)</span>
				</blockquote>
			</div>
		</div>
		<div class="fullscreen light">
			<div class="container">
				<div class="small dark-text _anim-items _no-repeat fade">IT в цифрах</div>
				<div class="info__programming">
					<div class="numbers">
						<div class="number _anim-items _no-repeat fade">
							<div class="number__body">
								<p>Более</p>
								<div class="num">
									17 тыс
								</div>
								<p>вакансий по Казахстану</p>
							</div>
						</div>
						<div class="number _anim-items _no-repeat fade">
							<div class="number__body">
								<p>Средняя заработная плата</p>
								<div class="num" title="790 000">
									790к ₸
								</div>
								<p>IT специалиста</p>
							</div>
						</div>
						<div class="number _anim-items _no-repeat fade">
							<div class="number__body">
								<p>Более</p>
								<div class="num">
									80 тыс
								</div>
								<p>вакансий по странам СНГ</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fullscreen dark">
			<div class="container">
				<div class="small light-text _anim-items _no-repeat fade">
					Зарплаты по отраслям
				</div>
				<div class="specialization">
					<p class="info _anim-items _no-repeat fade"> По данным <a href="hh.kz">hh.kz</a>, самые высокие зарплаты —
						<span class="highlight">от 900 тыс. тенге</span>, в первом квартале т.г. предлагали в основном
						специалистам из профобластей: <span class="italic"> "продажи", "строительство, недвижимость",
							"информационные технологии, интернет, телеком".</span>
					</p>
					<ul class="most _anim-items _no-repeat fade">
						<li class="sub-title bold">Самые востребованные специалисты IT в т.г:</li>
						<li><span class="bold">ведущий программист</span> — 690 вакансий с зарплатой <span class="highlight">450
								000 – 2 170 200</span></li>
						<li><span class="bold">удаленный программист</span> — 455 вакансий / <span class="highlight">400 000 - 2
								174 500</span></li>
						<li><span class="bold">программист SQL</span> — 334 вакансии/ <span class="highlight">250 000 - 2 115
								800</span></li>
						<li><span class="bold">программист JavaScript</span> — 286 вакансий / <span class="highlight">200 000 - 3
								028 800</span></li>
						<li><span class="bold">программист 1С</span> — 245 вакансий / <span class="highlight">200 000 - 1 390
								500</span></li>
						<li><span class="bold">программист С</span> — 219 вакансий / <span class="highlight">399 900 - 2 104
								900</span></li>
						<li><span class="bold">программист Python</span> — 161 вакансия / <span class="highlight">429 900 - 1 954
								000</span></li>
						<li><span class="bold">программист Java</span> — 140 вакансий / <span class="highlight">200 000 - 3 038
								000</span></li>
						<li><span class="bold">программист PHP</span> — 130 вакансий / <span class="highlight">299 900 - 1 569
								100</span></li>
						<li><span class="bold">программист C++</span> — 83 вакансии / <span class="highlight">349 900 - 1 708
								300</span></li>
					</ul>
					<p class="other _anim-items _no-repeat fade">Немаловажно и в какой отрасли ты работаешь. Самая высокая
						зарплата айтишников отмечается в
						горнодобывающей
						отрасли — 842 тыс. тенге, наименьшая, естественно, в госорганах — 218,7 тыс. тенге.</p>
					<p class="most-cost _anim-items _no-repeat fade">Наиболее высокооплачиваемыми ИКТ-профессиями являются
						IT-архитектор, разработчик
						программного обеспечения, специалист по кибербезопасности, инженер по искусственному интеллекту и
						бизнес-аналитик.</p>
				</div>
			</div>
		</div>
		<div class="fullscreen light">
			<div class="container">
				<div class="skills">
					<p class="small dark-text _anim-items _no-repeat fade">Самые часто требуемые навыки</p>
					<div class="skills-container">
						<ul class="skills-list">
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg"><img src="../../style/icons/python-svgrepo-com.svg" alt="python"></div>Python
								</div>
								<div>10.8</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/database-svgrepo-com.svg" alt="sql"></div>SQL
								</div>
								<div>9.8</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg"><img src="../../style/icons/java-4-logo-svgrepo-com.svg" alt="java"></div>Java
								</div>
								<div>7.5</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/js-official-svgrepo-com.svg" alt="java"></div>
									JavaScript
								</div>
								<div>6.6</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/reactjs-svgrepo-com.svg" alt="java"></div>React
								</div>
								<div>6.3</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-big"><img src="../../style/icons/typescript-svgrepo-com.svg" alt="java">
									</div>TypeScript
								</div>
								<div>6.2</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/database-svgrepo-com.svg" alt="java"></div>
									PostgreSQL
								</div>
								<div>5.8</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/csharp-svgrepo-com.svg" alt="java"></div>C#
								</div>
								<div>5.0</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-small"><img src="../../style/icons/php3-svgrepo-com.svg" alt="java"></div>PHP
								</div>
								<div>4.4</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/linux-svgrepo-com.svg" alt="java"></div>Linux
								</div>
								<div>3.7</div>
							</li>
						</ul>

						<ul class="skills-list second-list">
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/go-svgrepo-com.svg" alt="java"></div>Golang
								</div>
								<div>3.6</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-big"><img src="../../style/icons/gantt-chart-svgrepo-com.svg" alt="java">
									</div>Управление проектами
								</div>
								<div>3.1</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/vue-9-logo-svgrepo-com.svg" alt="java"></div>
									Vue.js
								</div>
								<div>2.8</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/github-svgrepo-com.svg" alt="java"></div>Git
								</div>
								<div>2.7</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-big"><img src="../../style/icons/kotlin-svgrepo-com.svg" alt="java"></div>
									Kotlin
								</div>
								<div>2.7</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-small"><img src="../../style/icons/cpp-svgrepo-com.svg" alt="java"></div>C++
								</div>
								<div>2.6</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-small"><img src="../../style/icons/group-svgrepo-com.svg" alt="java"></div>
									Управление людьми
								</div>
								<div>2.4</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd-big"><img src="../../style/icons/spring-svgrepo-com.svg" alt="java"></div>
									Java Spring Framework
								</div>
								<div>2.4</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/dotnet-svgrepo-com.svg" alt="java"></div>.NET
								</div>
								<div>2.3</div>
							</li>
							<li class="skills-list-item _anim-items _no-repeat fade">
								<div>
									<div class="svg pd"><img src="../../style/icons/docker-icon-svgrepo-com.svg" alt="java"></div>
									Docker
								</div>
								<div>2.3</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="dark">
			<div id="btn-top" class="top btn-up" @click="goTop">
				<div class="ico"><img src="../../style/icons/arrow-141-48.png" alt=""></div>
			</div>
			<div class="container_courses" id="#courses">
				<div class=" courses">

					<div class="fullscreen row">
						<div class=" _anim-items _no-repeat fade" v-for="(item, key) in courses" :key="key">
							<div class="item">
								<div class="img">
									<div class="title_course">
										{{ item.title_course }}
									</div>
									<img :src="`${uploadsPath}/courses/` + item.img" :alt="item.title_course" loading="lazy" />
								</div>
								<div class="content">
									<div class="text">
										{{ item.description }}
									</div>
									<button class="btn-primary-dark" @click="goToInfo(item.id, item.title_course)">
										Подробнее о курсе
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer class="fullscreen footer light">
			<div class="container flexible">
				<div class="footer__item social__block">
					<h4 class="dark-text">Контакты</h4>
					<ul>
						<li><a class="dark-text" href="https://wa.me/77770635167" target="_blank">8 (777) 063-51-67</a></li>
						<li><a class="dark-text" href="mailto:support@cybercourse.kz" target="_blank">support@cybercourse.kz</a>
						</li>
						<li><a class="dark-text" href="https://www.instagram.com/cybercourse.kz/" target="_blank">inst:
								cybercourse.kz</a></li>
					</ul>
				</div>
				<div class="footer__item navigate__bar">
					<h4 class="dark-text">Навигация</h4>
					<ul>
						<li><router-link class="dark-text" to="/">О
								компании</router-link></li>
						<!-- <li><router-link class="dark-text" to="/news">Новости</router-link></li> -->
					</ul>
				</div>
				<div class="footer__item copyright dark-text">
					Все права защищены, 2023, Кибер Курс ©
				</div>
			</div>
		</footer>
	</div>
	<router-view></router-view>
</template>
 
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import animateService from '../../../services/animate.service';
import { commonData } from '../../../utilis/common.data';
export default {
	name: 'courses_page',
	data: () => ({
		text: '',
		Allcourses: [],
		startY: null,
		currentSectionIndex: 0
	}),
	methods: {
		...mapActions(['GET_COURSES', 'GET_CATEGORIES', 'addToFavorites', 'removeFromFavorites']),
		goToInfo(number, title) {
			
			window.removeEventListener('scroll', this.onScroll);
			const name = this.replaceSpacesWithHyphens(title);
			this.$router.push('info/' + number + '/' + name);
		},
		toggleFavoriteCourse(courseId) {
			if (this.isCourseFavorite(courseId)) {
				this.removeFromFavorites(courseId);
			} else {
				this.addToFavorites(courseId);
			}
			this.$store.commit('saveFavoritesToLocalStorage');
		},
		replaceSpacesWithHyphens(courseName) {
			return courseName.replace(/\s+/g, '-');
		},
		addDot(num) {
			if (this.courses.length > 0) {
				return;
			}
			this.text = "Идет загрузка" + ".".repeat(num);
			if (typeof num === 'undefined') {
				num = 0;
			}
			let dots = (num + 1) % 4;
			setTimeout(() => {
				this.addDot(dots);
			}, 500);
		},
		toggleMenu() {
			let buttons = document.getElementsByClassName('burger_menu')
			for (let i = 0; i < buttons.length; i++) {
				let button = buttons[i];
				button.classList.toggle('opened_menu')
			}
			document.body.classList.toggle('close')
			let menuItems = document.getElementsByTagName('nav');
			for (let i = 0; i < menuItems.length; i++) {
				let menu = menuItems[i];
				menu.classList.toggle('open')
			}
		},
		closeMenu() {
			let buttons = document.getElementsByClassName('burger_menu')
			for (let i = 0; i < buttons.length; i++) {
				let button = buttons[i];
				button.classList.remove('opened_menu')
			}
			document.body.classList.remove('close')
			let menuItems = document.getElementsByTagName('nav');
			for (let i = 0; i < menuItems.length; i++) {
				let menu = menuItems[i];
				menu.classList.remove('open')
			}
		},
		onScroll() {
			let animItems = document.querySelectorAll('._anim-items');
			animateService.animOnScroll(animItems);
		},
	},
	computed: {
		...mapState(['courses']),
		...mapGetters(['isCourseFavorite']),
		uploadsPath() {
			return commonData.uploadsPath()
		},
	},
	async created() {
		setTimeout(() => {
			this.addDot();
		}, 50);
		await this.GET_COURSES();
		let animItems = document.querySelectorAll('._anim-items')
		if (animItems.length > 0) {
			window.addEventListener('scroll', this.onScroll);
		}
			window.addEventListener('touchmove', this.onScroll);
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('touchmove', this.onScroll);
	},
};
</script>
 
<style scoped lang="scss">
@import url('../../style/css/animate.css');
@import url('../../style/css/modules/main__courses.css');
@import url('../../style/css/helpers/loader.css');


.skills-list-item {
	transition-delay: .2s;
}
</style>
 