<template>
	<div class="wrapper">
		<div class="fullscreen relative town">
			<header class="absolute">
				<div class="container">
					<div class="flex_menu">
						<div class="logo" @click="this.$router.push('/')"><img src="../../style/icons/logo.svg" alt="Кибер Курс">
						</div>
						<div class="burger_menu" @click="toggleMenu">
							<div class="burger"></div>
						</div>
						<nav>
							<div class="menu__list">
								<router-link class="menu__item" active-class="active_tab" @click="closeMenu" to="/">О
									компании</router-link>
								<router-link class="menu__item" active-class="active_tab" @click="closeMenu"
									to="/courses">Курсы</router-link>
								<div class="menu__item soon">Новости</div>
								<!-- <router-link class="menu__item" active-class="active_tab" @click="closeMenu"
								to="/news">Новости</router-link> -->
							</div>
						</nav>
					</div>
				</div>
			</header>
			<div class="square">
				<div class="title"> <span class="big">К</span>ибер <span class="big">К</span>урс</div>
				<div class="subtitle">Учение. Познание. Реализация.</div>
				<!-- <button class="btn">Записаться</button> -->
			</div>
		</div>
		<div id="btn-top" class="top btn-up" @click="goTop">
			<div class="ico"><img src="../../style/icons/arrow-141-48.png" alt=""></div>
		</div>
		<div class="fullscreen dark">
			<div class="container">
				<div class="small light-text">Почему мы?</div>
				<div class="advantages">
					<div class="advantage__item  _anim-items fade _no-repeat">
						<div class="backflip">
							<div class="text__advantage">
								Есть возможность заниматься в группах или индивидуально
							</div>
						</div>
						<div>
							<figure>
								<img src="../../style/icons/today-48.png" alt="Гибкость" loading="lazy">
								<figcaption>Гибкость</figcaption>
							</figure>
						</div>
					</div>
					<div class="advantage__item  _anim-items  fade _no-repeat">
						<div class="backflip">
							<div class="text__advantage">
								Каждый курс насыщен практикой и содержит финальный проект, который можно использовать в
								портфолио
							</div>
						</div>
						<div>
							<figure>
								<img src="../../style/icons/medal-48.png" alt="Качество" loading="lazy">
								<figcaption>Качество</figcaption>
							</figure>
						</div>
					</div>
					<div class="advantage__item  _anim-items fade  _no-repeat ">
						<div class="backflip">
							<div class="text__advantage">
								Курсы по доступной цене, а так же есть скидки
							</div>
						</div>
						<div>
							<figure>
								<img src="../../style/icons/best-price-badge-48.png" alt="Низкие цены" loading="lazy">
								<figcaption>Низкие цены</figcaption>
							</figure>
						</div>
					</div>
					<div class="advantage__item  _anim-items _no-repeat fade">
						<div class="backflip">
							<div class="text__advantage">
								Наши преподаватели имеют большой опыт преподавания, а так же опыт работы в своих сферах
							</div>
						</div>
						<div>
							<figure>
								<img src="../../style/icons/businessman-48.png" alt="Профессионализм" loading="lazy">
								<figcaption>Профессионализм</figcaption>
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fullscreen light">
			<div class="container">
				<div class="courses_programs">
					<div class=" small dark-text _anim-items _no-repeat fade">После любого курса Вы:</div>
					<ul class="extrasmall dark-text">
						<li class="dark-text _anim-items _no-repeat transform-left">Сможете выстраивать и реализовывать
							алгоритмы</li>
						<li class="dark-text _anim-items _no-repeat transform-left">Разбираться в синтаксисе и структуре
							языка программирования</li>
						<li class=" dark-text _anim-items _no-repeat transform-left">Работать с одним из лучших
							редакторов
							кода</li>
						<li class=" dark-text _anim-items _no-repeat transform-left">Научитесь работать с системой
							GitHub</li>
						<li class=" dark-text _anim-items _no-repeat transform-left">Соберёте портфолио для будущей работы
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="fullscreen dark">
			<div class="created">
				<div class="container">
					<div class=" small less-margin light-text _anim-items _no-repeat fade">Об основателе</div>
					<div class="created_by">
						<div class="portrait _anim-items _no-repeat transform-left">
							<img
								src="https://sun9-63.userapi.com/impf/c848528/v848528772/1b6a38/yz1G5VCz_X4.jpg?size=622x778&quality=96&sign=136035e378625fd6f65b2c4b5193927a&type=album"
								alt="Основатель онлайн школы" loading="lazy">
						</div>
						<div class="description light-text _anim-items _no-repeat fade">
							<p><span class="highlight">Янголов Давид</span> - основал школу Кибер Курс в 2023 году</p>
							<p>Окончил <span class="highlight">«Университет Международного Бизнеса имени Кенежегали Сагадиева»
								</span> по специальности <span class="highlight">«Информационные системы»</span></p>
							<p>Имеет опыт работы <span class="highlight">full-stack</span> программистом в крупных торговых
								компаниях
							</p>
							<p>Считает, что <span class="highlight">знания</span> это лучшие инвестиции в будущее</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fullscreen no_padd light">
			<div class="flex-parent">
				<div class="info__block">
					<div class="small dark-text _anim-items _no-repeat fade">Следите за нами в социальных сетях</div>
					<div class="social__icons">
						<div class="icon__social _anim-items _no-repeat fade-next">
							<a href="https://www.instagram.com/cybercourse.kz/" target="_blank"><img
									src="../../style/icons/instagram-internet-media-svgrepo-com.svg" alt="inst:cybercourse.kz"
									loading="lazy"></a>

						</div>
						<div class="icon__social _anim-items _no-repeat fade-next">
							<a href="https://t.me/cybercourse_almaty" target="_blank"><img
									src="../../style/icons//internet-media-social-2-svgrepo-com.svg" alt="telegramm"
									loading="lazy"></a>
						</div>
						<div class="icon__social _anim-items _no-repeat fade-next">
							<a href="https://wa.me/77770635167" target="_blank"><img
									src="../../style/icons/internet-media-social-svgrepo-com.svg" alt="whatsapp" loading="lazy"></a>

						</div>
					</div>
				</div>
				<div class="request__send">
					<div class="body" v-if="!successSend">
						<h3 class="small light-text _anim-items _no-repeat fade" :class="{ '_active': oneSendQuestion }">
							Обратная связь</h3>
						<p :class="{ 'error': error, 'correct': correct }">{{ msg }}</p>
						<div class="form">
							<form @submit.prevent="sendRequest">
								<div class="item__form _anim-items _no-repeat fade" :class="{ '_active': oneSendQuestion }">
									<label for="name">Имя</label>
									<input type="text" id="name" autocomplete="off" v-model="formData.name" required>
								</div>
								<div class="item__form _anim-items _no-repeat fade" :class="{ '_active': oneSendQuestion }">
									<label for="mail">Почта</label>
									<input type="text" v-model="formData.mail" id="mail" autocomplete="off" required>
								</div>
								<div class="item__form _anim-items _no-repeat fade" :class="{ '_active': oneSendQuestion }">
									<label for="question">Вопрос</label>
									<textarea name="message" id="question" cols="30" rows="10" v-model="formData.message"
										required></textarea>
								</div>
								<input type="submit" class="btn-dark  _anim-items _no-repeat fade"
									:class="{ '_active': oneSendQuestion }" value="Отправить">
							</form>
						</div>
					</div>
					<div v-else class="block__alert">
						<p class="correct center">{{ msg }}</p>
						<button @click="clearMsg" class="button-primary btn-submit">ОК</button>
					</div>
				</div>
			</div>
		</div>
		<footer class="fullscreen">
			<div class="container flexible">
				<div class="footer__item social__block">
					<h4>Контакты</h4>
					<ul>
						<li><a class="footer-link" href="https://wa.me/77770635167" target="_blank">8 (777) 063-51-67</a></li>
						<li><a class="footer-link" href="mailto:support@cybercourse.kz"
								target="_blank">support@cybercourse.kz</a></li>
						<li><a class="footer-link" href="https://www.instagram.com/cybercourse.kz/" target="_blank">inst:
								cybercourse.kz</a></li>
					</ul>
				</div>
				<div class="footer__item navigate__bar">
					<h4>Навигация</h4>
					<ul>
						<li><router-link to="/courses">Курсы</router-link></li>
						<!-- <li><router-link to="/news">Новости</router-link></li> -->
					</ul>
				</div>
				<div class="footer__item copyright">
					Все права защищены, 2023, Кибер Курс ©
				</div>
			</div>
		</footer>
	</div>
</template>
<script>
import httpClient from '../../../services/http.service';
import animateService from '../../../services/animate.service';
export default {
	name: 'home_page',
	data() {
		return {
			formData: {
				name: '',
				mail: '',
				message: ''
			},
			msg: '',
			successSend: false,
			error: false,
			correct: false,
			oneSendQuestion: false,
		};
	},
	methods: {
		onScroll() {
			let animItems = document.querySelectorAll('._anim-items');
			animateService.animOnScroll(animItems);
		},
		validateEmail(mail) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(mail);
		},
		validateName(name) {
			const nameRegex = /^[a-zA-Z0-9а-яА-Я -]+$/;
			return nameRegex.test(name);
		},
		toggleMenu() {
			let buttons = document.getElementsByClassName('burger_menu')
			for (let i = 0; i < buttons.length; i++) {
				let button = buttons[i];
				button.classList.toggle('opened_menu')
			}
			document.body.classList.toggle('close')
			let menuItems = document.getElementsByTagName('nav');
			for (let i = 0; i < menuItems.length; i++) {
				let menu = menuItems[i];
				menu.classList.toggle('open')
			}
		},
		closeMenu() {
			let buttons = document.getElementsByClassName('burger_menu')
			for (let i = 0; i < buttons.length; i++) {
				let button = buttons[i];
				button.classList.remove('opened_menu')
			}
			document.body.classList.remove('close')
			let menuItems = document.getElementsByTagName('nav');
			for (let i = 0; i < menuItems.length; i++) {
				let menu = menuItems[i];
				menu.classList.remove('open')
			}
		},
		clearMsg() {
			this.oneSendQuestion = true
			this.msg = ''
			this.successSend = false
			this.error = false
			this.correct = false
			this.formData.message = ''
		},
		async sendRequest() {
			this.error = false
			this.msg = ''
			if (this.formData.name === '' || this.formData.mail === '' || this.formData.message === '') {
				this.error = true
				return this.msg = 'Заполните обязательные поля'
			}
			else {
				this.correct = this.validateEmail(this.formData.mail)
				if (this.correct === true) {
					this.msg = ''
					this.error = false
					const { data } = await httpClient.post('api/send-request', this.formData)
					if (data.success === true) {
						this.msg = 'Заявка отправлена. С вами свяжутся, ожидайте.'
						this.successSend = true
						setTimeout(() => {
							this.clearMsg()
						}, 3000);
					}
					else {
						this.correct = false
						this.error = true
						this.msg = 'Заявка не была отправлена. Попробуйте снова.'
						setTimeout(() => {
							this.clearMsg()
						}, 3000);
					}
				}
				else {
					this.error = true
					this.msg = 'Неккоректный адрес почты'
				}

			}
		},
	},
	async mounted() {
		let animItems = await document.querySelectorAll('._anim-items')
		if (animItems) {
			console.log(true);
			window.onscroll = this.onScroll
		}
	},
}
</script>
<style scoped lang="scss">
@import url('../../style/css/animate.css');
@import url('../../style/css/modules/main__home.css');
@import url('../../style/css/helpers/loader.css');
</style>